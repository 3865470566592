'use client';

import Button from 'src/components/Button';
import Text from 'src/components/Text/Text';
import Tooltip from 'src/components/Tooltip/Tooltip';
import SemanticChip from 'src/components/Utils/SemanticChip';
import HealthDisplay from 'src/utils/HealthDisplay';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import useIsMobileScreenSize from '../../hooks/useIsMobileScreenSize';
import useLiquidationStateStore from '../../stores/liquidationState/useLiquidationStateStore';
import { COMMON_UI_UTILS } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import { Info } from '@drift-labs/icons';

const DEV_MODE = false;

const LiquidationAlertChip = () => {
	const appEventEmitter = useAppEventEmitter();

	const { status, redAccounts, amberAccounts, tooltipDisabled, isConnected } =
		useLiquidationStateStore();

	const isMobile = useIsMobileScreenSize();

	if (status === 'green' || !isConnected) return null;

	if (isMobile)
		return (
			<div
				className={twMerge(
					'relative flex items-center justify-center h-full p-[6px] space-x-2 text-xs rounded cursor-pointer border',
					status === 'amber' ? 'border-warn-yellow' : 'border-negative-red'
				)}
				onClick={() => {
					appEventEmitter.emit('showMobileInfoDrawer', 'accountHealth');
				}}
			>
				<Info
					color={
						status === 'amber' ? 'var(--warn-yellow)' : 'var(--negative-red)'
					}
					size={20}
				/>
			</div>
		);

	return (
		<Tooltip
			allowHover
			disabled={tooltipDisabled}
			content={<LiquidationAlertTable />}
		>
			<div
				className={`text-text-default flex items-center px-2 py-1 space-x-2 rounded text-xs hover:brightness-125 hover:cursor-pointer`}
				style={{
					background:
						status === 'red'
							? 'var(--negative-bg-gradient)'
							: 'var(--warning-bg-gradient)',
				}}
			>
				<SemanticChip status={status} className="mr-2" />
				{redAccounts ? (
					<>
						{redAccounts} acct{redAccounts > 1 ? 's' : ''} in liquidation
					</>
				) : amberAccounts ? (
					<>
						{amberAccounts} acct{amberAccounts > 1 ? 's' : ''} at risk
					</>
				) : (
					<></>
				)}
			</div>
		</Tooltip>
	);
};

export const LiquidationAlertTable = () => {
	const { status, uniqueAccounts, isConnected, showDeposit } =
		useLiquidationStateStore();

	if (status === 'green' || !isConnected) return null;

	return (
		<div className="flex flex-col space-y-2 shrink-0">
			{uniqueAccounts.length === 0 || DEV_MODE ? (
				<>
					<Text.BODY3>Accounts no longer at risk</Text.BODY3>
				</>
			) : (
				uniqueAccounts.map(([account, _riskLevel]) => {
					return (
						<div
							key={account.userId}
							className="flex items-center justify-between select-none"
						>
							<Text.BODY2 className="mr-4 shrink-0">{account.name}</Text.BODY2>
							<span className="flex-grow" />
							<HealthDisplay
								health={isConnected ? account.client?.getHealth() : 100}
							/>
							<span className="pr-2" />
							<Button.Secondary
								size="SMALL"
								onClick={() => {
									showDeposit(
										0,
										COMMON_UI_UTILS.getUserKey(
											account.userId,
											account.authority
										)
									);
								}}
							>
								Deposit
							</Button.Secondary>
						</div>
					);
				})
			)}
		</div>
	);
};

export default LiquidationAlertChip;
