'use client';

import { PropsWithChildren } from 'react';
import TopBar from 'src/components/TopBar';
import CentreFillScroller from 'src/components/Utils/CentreFillScroller';
import { MobileNavigationMenu } from '../Navigation/MobileNavigation';
import Footer from './Footer';
import { NavigationOptionKey } from '../Navigation';
import LayoutCustomiser from '../TradePageGrid/LayoutCustomiser';
import { useWindowSizeIsGreaterThan } from 'src/hooks/useWindowSize';
import useNavigationStore from 'src/stores/useNavigationStore';

const NavFooterLayout = (
	props: PropsWithChildren<{
		currentPage: NavigationOptionKey;
		overflowAuto?: boolean;
		pageBackground?: string;
		/**
		 * True by default
		 */
		hideScroll?: boolean;
		middleClassName?: string;
		middleFillSpace?: boolean;
		useCustomMiddleWrapper?: boolean;
	}>
) => {
	const showMobileNavMenu = useNavigationStore((s) => s.showMobileNavMenu);
	const isTabletOrLargerScreenSize = useWindowSizeIsGreaterThan('xs');

	return (
		<div
			className="w-screen h-screen"
			style={{
				background: props.pageBackground,
			}}
		>
			<CentreFillScroller
				overflowAuto={props.overflowAuto}
				hideScroll={props.hideScroll === false ? false : true}
				fillSpace={props.middleFillSpace}
				innerClassName={props.middleClassName}
				useCustomMiddleWrapper={props.useCustomMiddleWrapper}
				top={
					<div className="header">
						<TopBar currentPage={props.currentPage} />
						<LayoutCustomiser />
					</div>
				}
				middle={
					<>
						{props.children}
						{showMobileNavMenu && <MobileNavigationMenu />}
					</>
				}
				bottom={
					<div id="footer">
						{isTabletOrLargerScreenSize ? (
							<footer className="footer">
								<Footer />
							</footer>
						) : (
							// <MobileTabBar currentPage={props.currentPage} />
							<></>
						)}
					</div>
				}
			/>
		</div>
	);
};

export default NavFooterLayout;
