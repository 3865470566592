'use client';

import { useTheme } from 'next-themes';
import GradientCheck from '../Icons/GradientCheck';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';

const LayoutOptionButton = ({
	// label,
	value,
	selected,
	onClick,
}: {
	// label: string;
	value: string;
	selected: boolean;
	onClick: () => void;
}) => {
	const theme = useTheme().theme ?? DriftTheme.dark;
	return (
		<div
			className={`relative flex flex-col items-center hover:cursor-pointer hover:text-text-emphasis ${
				selected ? 'text-text-emphasis' : 'text-text-secondary'
			}`}
			onClick={onClick}
		>
			<div>
				<img src={`/assets/icons/buttonIcons/layout/${value}-${theme}.svg`} />
			</div>
			{/* <div className="pt-2">{label}</div> */}

			{selected && (
				<div className="absolute -top-2 -right-3">
					<GradientCheck />
				</div>
			)}
		</div>
	);
};

export default LayoutOptionButton;
