import React, { useEffect, useRef } from 'react';
import { fromEvent, buffer, debounceTime, filter } from 'rxjs';
import { twMerge } from 'tailwind-merge';

/**
 * SecretButton Component
 *
 * This component creates a clickable element that activates a secret function
 * when clicked 5 times in quick succession (within 300ms between clicks).
 *
 * It uses RxJS to handle the click stream and detect the activation pattern.
 * The component uses a div instead of a button to avoid inheriting button styles.
 *
 * Props:
 * - onSecretActivate: Function to call when the secret is activated
 * - children: React nodes to render inside the clickable element
 * - className: Optional CSS class name for styling
 */

interface SecretButtonProps {
	onSecretActivate: () => void;
	children: React.ReactNode;
	className?: string;
}

const SecretButton: React.FC<SecretButtonProps> = ({
	onSecretActivate,
	children,
	className,
}) => {
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!elementRef.current) return;

		const clicks$ = fromEvent(elementRef.current, 'click');
		const buff$ = clicks$.pipe(
			buffer(clicks$.pipe(debounceTime(300))),
			filter((clicks) => clicks.length === 5)
		);

		const subscription = buff$.subscribe(() => {
			onSecretActivate();
		});

		return () => subscription.unsubscribe();
	}, [onSecretActivate]);

	return (
		<div
			ref={elementRef}
			className={twMerge(`select-none`, className)}
			tabIndex={0}
		>
			{children}
		</div>
	);
};

export default SecretButton;
