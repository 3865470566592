'use client';

import useS3Params from 'src/hooks/useS3Params';
import StatusBar from './StatusBar';

export const ArbitraryStatusBar = () => {
	const data = useS3Params();

	const status = data?.statusBar?.status ?? 'warn';
	const message = data?.statusBar?.message;

	if (!message) return null;

	return (
		<StatusBar
			message={message}
			colorClass={
				status === 'warn'
					? 'bg-orange-50'
					: status === 'error'
					? 'bg-red-50'
					: 'bg-orange-50'
			}
		/>
	);
};
