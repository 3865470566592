'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import StatusBar from './StatusBar';

const EmulationBar = () => {
	const viewingAuthority = useDriftStore((s) => s.authorityParam);
	const isEmulatingAccount = useDriftStore((s) => s.isEmulatingAccount);
	const shouldRender = isEmulatingAccount;

	if (!shouldRender) return undefined;

	const noticeText = `You are currently viewing ${viewingAuthority?.replace(
		'authority=',
		''
	)}'s wallet. Actions will not work. Access app.drift.trade to connect your own wallet.`;

	return <StatusBar message={noticeText} />;
};

export default EmulationBar;
