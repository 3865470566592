'use client';

import { ExchangeStatus } from '@drift-labs/sdk';
import Tooltip from 'src/components/Tooltip/Tooltip';
import SemanticChip, {
	SEMANTIC_STATUS,
} from 'src/components/Utils/SemanticChip';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useIsMainnet from 'src/hooks/useIsMainnet';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDevSwitchIsOn from '../../hooks/useDevSwitchIsOn';
import React, { PropsWithChildren } from 'react';
import constants from 'src/constants/autogenerated.json';
import { twMerge } from 'tailwind-merge';
import PriorityFeeDisplay from '../PriorityFeeDisplay';
import CurrentRpcHealth from '../CurrentRpcHealth';
import LiteProSwitch from '../LiteProSwitch';
import Env from 'src/environmentVariables/EnvironmentVariables';
import SecretButton from 'src/components/SecretButton';
import { notify } from 'src/utils/notifications';

const FOOTER_HEIGHT_PX = 28;

const statusToNum = (status: SEMANTIC_STATUS) => {
	switch (status) {
		case 'red':
			return 0;
		case 'amber':
			return 1;
		case 'green':
			return 2;
	}
};

const numToStatus = (status: number): SEMANTIC_STATUS => {
	switch (status) {
		case 0:
			return 'red';
		case 1:
			return 'amber';
		case 2:
			return 'green';
		default:
			return 'green';
	}
};

const getMinimumHealth = (
	healthOne: SEMANTIC_STATUS,
	healthTwo: SEMANTIC_STATUS
) => {
	const minHealth = Math.min(statusToNum(healthOne), statusToNum(healthTwo));

	return numToStatus(minHealth);
};

const exchangeStatusToSemanticStatus = (
	exchangeStatus: number
): SEMANTIC_STATUS => {
	switch (exchangeStatus) {
		case ExchangeStatus.ACTIVE:
			return 'green';
		case ExchangeStatus.PAUSED:
			return 'red';
		default:
			return 'amber';
	}
};

const exchangeStatusToMessage = (exchangeStatus: number) => {
	switch (exchangeStatus) {
		case ExchangeStatus.ACTIVE:
			return `Exchange Status: Active`;
		case ExchangeStatus.DEPOSIT_PAUSED:
			return `Exchange Status: Deposits Paused`;
		case ExchangeStatus.WITHDRAW_PAUSED:
			return `Exchange Status: Withdraws Paused`;
		case ExchangeStatus.AMM_PAUSED:
			return `Exchange Status: AMM Paused`;
		case ExchangeStatus.FILL_PAUSED:
			return `Exchange Status: Fills Paused`;
		case ExchangeStatus.LIQ_PAUSED:
			return `Exchange Status: Liquidations Paused`;
		case ExchangeStatus.FUNDING_PAUSED:
			return `Exchange Status: Funding Paused`;
		case ExchangeStatus.SETTLE_PNL_PAUSED:
			return `Exchange Status: PNL Settling Paused`;
		case ExchangeStatus.PAUSED:
			return `Exchange Status: Paused`;
	}
};

const getOverralStatusMessage = (
	exchangeStatusMessageBlockchain: string,
	exchangeStatusMessageAws: string
) => {
	const messageParts: string[] = [];
	let overallStatusMessage = '';

	if (exchangeStatusMessageBlockchain) {
		messageParts.push(exchangeStatusMessageBlockchain);
	}

	if (exchangeStatusMessageAws) {
		messageParts.push(exchangeStatusMessageAws);
	}

	overallStatusMessage = messageParts.join('. ');

	if (!overallStatusMessage) {
		overallStatusMessage = '-';
	}

	return overallStatusMessage;
};

const Divider = () => {
	return (
		// Rare case where non-semantic colour is allowed
		<div
			className={`w-[1px] h-[${FOOTER_HEIGHT_PX - 14}px] bg-stroke-secondary`}
		/>
	);
	// return <span>{'-'}</span>;
	// return <div className="w-[1px] h-full bg-darkBlue-90" />
};

const FooterSection = (
	props: PropsWithChildren<{ withSplitter?: boolean; className?: string }>
) => {
	const childrenMap = React.Children.toArray(props.children);

	const shouldRenderDivider = (index: number) => {
		if (!props.withSplitter) return false;

		if (!childrenMap[index]) return false;

		return true;
	};

	return (
		<div
			className={twMerge(
				'flex items-center h-full space-x-2.5',
				props.className
			)}
		>
			{React.Children.map(props.children, (child, index) => {
				// This is weird but this is how we handle the "optionally" rendered things in the footers
				if (child) {
					return (
						<React.Fragment>
							{child}
							{shouldRenderDivider(index) && <Divider />}
						</React.Fragment>
					);
				}
			})}
		</div>
	);
};

const Footer = (props: { hideStatus?: boolean }) => {
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();

	const { status: exchangeStatusAws, message: statusMessageAws } =
		useDriftStore((s) => s.driftStatus.mainStatus);

	const exchangeStatusBlockchain = driftClientIsReady
		? driftClient?.getStateAccount()?.exchangeStatus
		: ExchangeStatus.ACTIVE;

	const exchangeStatusBlockchainSemantic = exchangeStatusToSemanticStatus(
		exchangeStatusBlockchain
	);

	const overallStatus = getMinimumHealth(
		exchangeStatusAws,
		exchangeStatusBlockchainSemantic
	);

	const overallStatusMessage = getOverralStatusMessage(
		exchangeStatusToMessage(exchangeStatusBlockchain),
		statusMessageAws
	);

	const isMainnet = useIsMainnet();

	const devSwitchIsOn = useDevSwitchIsOn();
	const useMaxLev = useDriftStore((s) => s.tradeForm.maxLeverageSelected);

	return (
		<div
			className={`items-center justify-between w-full py-1 text-text-default hover:cursor-default xs:hidden sm:flex h-[${FOOTER_HEIGHT_PX}px] px-4 bg-container-bg border-t border-container-border`}
		>
			{props.hideStatus || exchangeStatusBlockchain === undefined ? (
				<span />
			) : (
				<FooterSection withSplitter>
					<Tooltip
						content={
							<span className="capitalize text-text-default">
								{overallStatusMessage}
							</span>
						}
						placement="top"
					>
						<div className="flex items-center space-x-1">
							<SemanticChip className="mr-1" status={overallStatus} />
							<span className="text-xs text-text-default">
								{overallStatus === 'green'
									? 'Operational'
									: overallStatus === 'amber'
									? 'Some interruptions'
									: 'Major interruptions'}
							</span>
						</div>
					</Tooltip>

					<CurrentRpcHealth />

					<PriorityFeeDisplay />

					{Env.enableLiteMode && <LiteProSwitch />}
				</FooterSection>
			)}

			{devSwitchIsOn && useMaxLev ? (
				<span className="text-red-50">MAX LEV</span>
			) : undefined}

			<FooterSection className="hidden md:flex">
				{(devSwitchIsOn || !isMainnet) && (
					<>
						<span className="text-xs">
							{isMainnet ? 'mainnet-beta' : 'devnet'}
						</span>
						<Divider />
					</>
				)}
				<SecretButton
					onSecretActivate={() => {
						const currentDevSwitchValue = localStorage.getItem('devswitch');
						let devSwitchIsOn = false;
						if (currentDevSwitchValue === 'true') {
							devSwitchIsOn = true;
						}

						localStorage.setItem('devswitch', devSwitchIsOn ? 'false' : 'true');

						notify({
							type: 'info',
							message: `Toggling devswitch ${devSwitchIsOn ? 'off' : 'on'}`,
						});
					}}
				>
					<span className="text-xs">v{constants.UI_VERSION}</span>
				</SecretButton>
			</FooterSection>
		</div>
	);
};

export default Footer;
