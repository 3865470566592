import GradientText from 'src/components/Utils/GradientText';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type Options = {
	label: string;
	value: string;
}[];

type Props = {
	options: Options;
	selected: string;
	onChange: (value: string) => void;
	className?: string;
};

const HorizontalSwitch = (props: Props) => {
	return (
		<div
			className={`flex border border-[2px] rounded-md border-darkBlue-30 ${
				props.className ?? ''
			}`}
		>
			{props.options.map((option, i) => (
				<div
					key={option.value}
					className={twMerge(
						'bg-main-bg px-4 pt-1.5 pb-1 border-r border-darkBlue-30 last:border-r-0 text-sm text-darkBlue-30 hover:cursor-pointer hover:text-darkBlue-20',
						i === 0 && 'rounded-l-md',
						i === props.options.length - 1 && 'rounded-r-md'
					)}
					onClick={() => {
						props.onChange(option.value);
					}}
				>
					{option.value === props.selected ? (
						<GradientText>{option.label}</GradientText>
					) : (
						<span>{option.label}</span>
					)}
				</div>
			))}
		</div>
	);
};

export default HorizontalSwitch;
