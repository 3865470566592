'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { StatusBarWithContent } from './StatusBar';

const NewVersionBar = () => {
	const showNewVersionStatusBar = useDriftStore(
		(s) => s.statusBars?.showNewVersionStatusBar
	);

	if (!showNewVersionStatusBar) return undefined;

	return (
		<StatusBarWithContent className="py-1 bg-status-neutral-bg">
			<div className="flex flex-col items-center justify-center md:flex-row">
				A new update is available. Refresh the app to get the latest
				improvements!
			</div>
		</StatusBarWithContent>
	);
};

export default NewVersionBar;
