'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import StatusBar from './StatusBar';

const MaintenanceModeBar = () => {
	const maintenanceModeEstimateTs = useDriftStore(
		(s) => s.driftStatus.historyServerStatus.maintenanceModeEstimateTs
	);
	const maintenanceMode = useDriftStore(
		(s) => s.driftStatus.historyServerStatus.maintenanceMode
	);

	if (!maintenanceMode) return undefined;

	let noticeText = `Drift's history server is undergoing scheduled maintenance. Account, Trade, and Market History may be unavailable or inconsistent during this period.`;

	if (maintenanceModeEstimateTs) {
		noticeText += ` Estimated completion : ${new Date(
			maintenanceModeEstimateTs * 1000
		).toLocaleString()}`;
	}

	return <StatusBar message={noticeText} />;
};

export default MaintenanceModeBar;
