'use client';

import React from 'react';
import { Help } from '@drift-labs/icons';
import Text from 'src/components/Text/Text';
import useGoToRoute from 'src/hooks/useGoToRoute';
import { DRIFT_DISCORD_URL } from 'src/constants/constants';

const MobileHelpButton = () => {
	//const currentWalletAuthority = useCurrentAuthority();
	const goToRoute = useGoToRoute();

	// const isEmulatingAccount = useDriftStore((s) => s.isEmulatingAccount);

	const openSupportWidget = () => {
		goToRoute(DRIFT_DISCORD_URL);
		//UI_UTILS.openSupportWidget(currentWalletAuthority?.toString(), isEmulatingAccount);
	};

	return (
		<button
			className={`hover:opacity-75 text-text-default w-full flex flex-col space-y-0.5`}
			onClick={openSupportWidget}
		>
			<div className="flex flex-row items-center space-x-1">
				<Help size={16} color="var(--text-default)" />
				<Text.BODY3>Support</Text.BODY3>
			</div>
			<div className="whitespace-nowrap ">
				<Text.MICRO1 className="text-interactive-link">Get Help</Text.MICRO1>
			</div>
		</button>
	);
};

export default MobileHelpButton;
