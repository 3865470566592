'use client';

import { Share, Settings, Layout } from '@drift-labs/icons';
import React, { useEffect, useRef, useState } from 'react';
import useSafePush from 'src/hooks/useSafePush';
import useDevSwitchIsOn from '../hooks/useDevSwitchIsOn';
import useDriftActions from '../hooks/useDriftActions';
import useIsMobileScreenSize from '../hooks/useIsMobileScreenSize';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import Button from './Button';
import ConnectWalletButton from './ConnectWalletButton';
import DriftGraphicLogo from './Icons/DriftGraphicLogo';
import DriftTextLogo from './Icons/DriftTextLogo';
import LiquidationAlertChip from './Liquidations/LiquidationAlertChip';
import DesktopNavigation from './Navigation/DesktopNavigation';
import { MobileNavigationMenuToggle } from './Navigation/MobileNavigation';
import StatusBars from './StatusBars/StatusBars';
import { twMerge } from 'tailwind-merge';
import useShowGridCustomiser from 'src/hooks/useShowGridCustomiser';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { DialectNotificationsButton } from './DialectNotifications';
import DevTopDropDown from '../dev/components/DevTopDropDown';
import { FuelChip } from './FuelRewards/FuelChip';

const TopBar = (props?: { currentPage: string }) => {
	// const connected = useWalletIsConnected();

	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket?.current.marketId
	);

	const safePush = useSafePush();

	const actions = useDriftActions();

	const [showGridCustomiser, setShowGridCustomiser] = useShowGridCustomiser();

	const [currentNavSelection, setCurrentNavSelection] = useState(
		props?.currentPage || 'perpetuals'
	);

	const topBarRef = useRef<HTMLDivElement>(null);

	const showDevModal = () => {
		actions.showModal('showDevModal');
	};

	const showSettingsModal = () => {
		actions.showModal('showSettingsModal');
	};

	const devSwitchIsOn = useDevSwitchIsOn();

	const handleOpenGridCustomiser = (event: MouseEvent) => {
		event.stopPropagation();
		setShowGridCustomiser(!showGridCustomiser);
	};

	const handleCloseGridCustomiser = () => {
		setShowGridCustomiser(false);
	};

	useEffect(() => {
		// keep selection correct if they switch markets without touching navbar
		if (selectedMarketId.isPerp && currentNavSelection == 'spot') {
			setCurrentNavSelection('perpetuals');
		} else if (
			!selectedMarketId.isPerp &&
			currentNavSelection == 'perpetuals'
		) {
			setCurrentNavSelection('spot');
		}
	}, [selectedMarketId?.key, props?.currentPage]);

	const isMobile = useIsMobileScreenSize();

	return (
		<>
			<StatusBars />
			<nav
				id="topbar"
				className={twMerge(
					`flex flex-grow px-2 sm:px-4 py-2 bg-container-bg border-b border-container-border sm:border-none sm:bg-main-bg`
				)}
				onClick={handleCloseGridCustomiser}
			>
				<div
					className={`flex justify-between items-center top-0 left-0 w-full font-display`}
					ref={topBarRef}
				>
					<div className={`flex justify-between items-center md:space-x-4`}>
						<a
							onClick={() => {
								safePush('/SOL-PERP');
							}}
							className="mr-2 hover:cursor-pointer"
						>
							<div className="w-full h-full md:hidden">
								<DriftGraphicLogo className={'min-w-[28px]'} />
							</div>
							<div className="hidden w-full h-full md:block">
								<DriftTextLogo />
							</div>
						</a>

						{!isMobile && <DesktopNavigation />}
					</div>

					<div className="flex items-center h-full space-x-2 flex-fill max-h-[32px]">
						{devSwitchIsOn && (
							<>
								<button
									onClick={showDevModal}
									className="mx-2 text-xs text-text-default"
								>
									<Share />
								</button>
							</>
						)}
						<LiquidationAlertChip />

						<FuelChip />

						{Env.enableLiteMode && (
							<Button.Secondary
								size="MEDIUM"
								iconOnly
								onClick={handleOpenGridCustomiser}
								className="hidden px-1 lg:flex text-text-default"
							>
								<Layout size={24} />
							</Button.Secondary>
						)}

						{Env.dialectNotificationsEnabled && <DialectNotificationsButton />}

						{!isMobile && (
							<Button.Secondary
								size="MEDIUM"
								iconOnly
								onClick={showSettingsModal}
								className="px-1"
							>
								<Settings size={24} color="var(--text-default)" />
							</Button.Secondary>
						)}

						{/* {connected && <MarginAccountButton className="h-full" />} */}

						<ConnectWalletButton />

						{isMobile && <MobileNavigationMenuToggle />}
					</div>
				</div>
			</nav>
			{devSwitchIsOn && !isMobile && (
				<div className="absolute left-1/2 top-0 h-[10px] w-[30px]">
					<DevTopDropDown />
				</div>
			)}
		</>
	);
};

export default React.memo(TopBar);
