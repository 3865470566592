import React from 'react';
import { SuccessFilled } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';

const GradientCheck = () => {
	return (
		<div className="w-5 h-5 rounded-full bg-text-emphasis -p-1">
			<SuccessFilled
				size={24}
				className={twMerge(
					'[&>svg>path]:fill-[url(#light-app-gradient)] relative -top-[2px] -left-[2px]'
				)}
			/>
		</div>
	);
};

export default GradientCheck;
