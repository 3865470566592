'use client';

import { useState } from 'react';
import useDriftActions from 'src/hooks/useDriftActions';
import useCurrentSettings from '../hooks/useCurrentSettings';
import { PriorityFeeCalculator } from '../utils/PriorityFeeCalculator';
import Text from './Text/Text';
import usePriorityFeeStore from '../stores/usePriorityFeeStore';
import useUiUpdateInterval from '../hooks/useUiUpdateInterval';
import useDevSwitchIsOn from '../hooks/useDevSwitchIsOn';
import { PriorityFee } from '@drift-labs/icons';
import PriorityFeePopup from './PriorityFeePopup';
import PopoverWrapper from './PopoverWrapper';
import useTargetedPopover from 'src/hooks/useTargetedPopover';

const PriorityFeeDisplay = (props: {
	mobile?: boolean;
	showIcon?: boolean;
	onClick?: () => void;
}) => {
	const [settings] = useCurrentSettings();
	const currentPriorityFeeSetting = settings.priorityFee;
	const actions = useDriftActions();
	const priorityFeeStoreReady = usePriorityFeeStore((s) => s.ready);
	const getPriorityFeeToUse = usePriorityFeeStore((s) => s.getPriorityFeeToUse);
	const devSwitchIsOn = useDevSwitchIsOn();

	const showNetworkSettingsModal = () => {
		actions.showModal('showNetworkModal');
	};

	const [priorityFeeEstimate, setPriorityFeeEstimate] = useState(0);

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			placement: 'top',
		},
		{
			offset: 12,
			disableAutoPlacement: true,
		}
	);

	useUiUpdateInterval(
		() => {
			if (!priorityFeeStoreReady) {
				setPriorityFeeEstimate(0);
				return;
			}

			const priorityFeeCUPrice = getPriorityFeeToUse();
			setPriorityFeeEstimate(
				PriorityFeeCalculator.getPriorityFeeInSolForComputeUnitPrice(
					priorityFeeCUPrice
				)
			);
		},
		true,
		false
	);

	if (props.mobile) {
		return (
			<button
				className={`hover:opacity-75 text-text-default w-full flex flex-col space-y-0.5`}
				onClick={showNetworkSettingsModal}
			>
				<div className="flex flex-row items-center space-x-1 whitespace-nowrap">
					{props.showIcon && (
						<PriorityFee
							size={18}
							color="var(--text-default)"
							className="mt-[-2px] relative top-[1px]"
						/>
					)}
					<Text.BODY3>Priority Fee</Text.BODY3>
				</div>
				<div className="capitalize whitespace-nowrap">
					<Text.MICRO1 className="text-interactive-link">
						{currentPriorityFeeSetting}{' '}
						{devSwitchIsOn && ` (${priorityFeeEstimate} SOL)`}
					</Text.MICRO1>
				</div>
			</button>
		);
	}

	return (
		<>
			{isPopoverOpen && (
				<PopoverWrapper
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
				>
					<PriorityFeePopup
						onClose={() => {
							setIsPopoverOpen(false);
						}}
					/>
				</PopoverWrapper>
			)}
			<button
				ref={refs.setReference}
				onClick={() => setIsPopoverOpen(true)}
				className={`text-xs hover:opacity-75 flex space-x-2`}
				{...getReferenceProps()}
			>
				<div className="flex items-center">
					<PriorityFee size={17} />
					<Text.BODY3 className="ml-0.5 text-text-default">
						Priority Fees:
					</Text.BODY3>
					<Text.BODY3 className="ml-1 capitalize text-purple-40">
						{currentPriorityFeeSetting}
					</Text.BODY3>
					{devSwitchIsOn && (
						<Text.BODY3 className="ml-1 text-text-default">{`(${priorityFeeEstimate} SOL)`}</Text.BODY3>
					)}
				</div>
			</button>
		</>
	);
};

export default PriorityFeeDisplay;
