'use client';

import useTradeModeSetting, { TradeMode } from 'src/hooks/useTradeModeSetting';
import Switch from './Switch';
import Text from './Text/Text';
import { twMerge } from 'tailwind-merge';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import {
	LITE_GRID_CONFIGURATIONS,
	PRO_GRID_CONFIGURATIONS,
} from './TradePageGrid/GridConfigurations';
import Env from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useLayoutSplitSetting from 'src/hooks/useLayoutSplitSetting';

const LiteProSwitch = () => {
	const { captureEvent } = usePostHogCapture();
	const [layoutSelection, setLayoutSelection] = useLayoutConfigSetting();
	const [layoutSplit, setLayoutSplit] = useLayoutSplitSetting();
	const [tradeMode, setTradeMode] = useTradeModeSetting();

	const gridConfigurations =
		Env.enableLiteMode && tradeMode === TradeMode.LITE
			? LITE_GRID_CONFIGURATIONS
			: PRO_GRID_CONFIGURATIONS;

	const handleTradeModeSelection = (value: TradeMode) => {
		setTradeMode(value);

		captureEvent('changed_trade_mode_with_switch', { trade_mode: value });

		const selectedGridConfiguration =
			gridConfigurations.find((config) => config.value === layoutSelection) ||
			gridConfigurations[0];

		if (value === 'lite' && !selectedGridConfiguration.isLiteMode) {
			setLayoutSplit(
				selectedGridConfiguration.value === 'split' ||
					selectedGridConfiguration.value === 'splitMirrored'
					? true
					: false
			);
			if (['split', 'default'].includes(selectedGridConfiguration.value)) {
				setLayoutSelection('defaultLite');
			} else {
				setLayoutSelection('mirroredLite');
			}
		} else if (value === 'pro' && selectedGridConfiguration.isLiteMode) {
			if (selectedGridConfiguration.value === 'defaultLite') {
				if (layoutSplit) {
					setLayoutSelection('split');
				} else {
					setLayoutSelection('default');
				}
			} else {
				if (layoutSplit) {
					setLayoutSelection('splitMirrored');
				} else {
					setLayoutSelection('mirrored');
				}
			}
		}
	};

	const handleToggleLitePro = () => {
		handleTradeModeSelection(
			tradeMode === TradeMode.LITE ? TradeMode.PRO : TradeMode.LITE
		);
	};

	return (
		<span // Switch has a <button> inside of it, so we can't use a <button> here
			className="flex flex-row items-center cursor-pointer"
			onClick={handleToggleLitePro}
		>
			<Text.MICRO1
				className={twMerge(
					'transition-all',

					tradeMode === TradeMode.LITE ? 'text-text-default' : 'text-text-label'
				)}
			>
				Lite
			</Text.MICRO1>
			<Switch
				checked={tradeMode === TradeMode.PRO}
				onChange={handleToggleLitePro}
				className="relative top-[-1px] mr-2 ml-0.5"
			/>
			<Text.MICRO1
				className={twMerge(
					'transition-all',

					tradeMode === TradeMode.PRO ? 'text-text-default' : 'text-text-label'
				)}
			>
				Pro
			</Text.MICRO1>
		</span>
	);
};

export default LiteProSwitch;
