'use client';

import styled from '@emotion/styled';
import { Portal } from '@headlessui/react';
import HorizontalSwitch from 'src/components/Buttons/HorizontalSwitch';
import XButton from 'src/components/Buttons/XButton';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import useNotificationPlacementSetting from 'src/hooks/useNotificationPlacementSetting';
import useShowGridCustomiser from 'src/hooks/useShowGridCustomiser';
import React, { useEffect, useRef, useState } from 'react';
import {
	PRO_GRID_CONFIGURATIONS as GRID_CONFIGURATIONS,
	LITE_GRID_CONFIGURATIONS,
} from './GridConfigurations';
import LayoutOptionButton from './LayoutOptionButton';
import NotificationOptionButton from './NotificationOptionButton';
import { useTheme } from 'next-themes';
import ThemeOptionButton from './ThemeOptionButton';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import Env, {
	DriftTheme,
} from '../../environmentVariables/EnvironmentVariables';
import { twMerge } from 'tailwind-merge';
import TradeModeOptionButton from './TradeModeOptionButton';
import useTradeModeSetting, { TradeMode } from 'src/hooks/useTradeModeSetting';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { NOTIFICATION_CONFIGURATIONS } from 'src/constants/constants';

const LITE_MODE_ENABLED = Env.enableLiteMode;

type SettingType = 'notifications' | 'layout' | 'theme' | 'tradeMode';

const TRADE_MODES = [
	{ label: 'Lite', value: TradeMode.LITE },
	{ label: 'Pro', value: TradeMode.PRO },
];

const THEMES = [
	{ label: 'Dark', value: DriftTheme.dark },
	{ label: 'Light', value: DriftTheme.light },
];

const LayoutCustomiserWrapper = styled.div`
	max-height: 0;
	transition: max-height 200ms ease-out;
	-webkit-transition: max-height 200ms ease-out;
`;

const NotficationPreview = styled.div`
	transition: right 250ms ease-out 0s, top 250ms ease-out 0s;
	position: absolute;
	z-index: 100;
	transform: translate(-100%, -100%);
	-webkit-transform: translate(-100%, -100%);
	top: 0;
	right: 0;

	&.bottomLeft {
		top: calc(100% + 10px);
		right: calc(100% - 655px);
	}

	&.bottomRight {
		top: calc(100% + 10px);
		right: calc(0% - 335px);
	}

	&.topRight {
		top: calc(0% + 220px);
		right: calc(0% - 335px);
	}
`;

const LayoutCustomiser = () => {
	// Record the initial / changed selection of each setting type for posthog event capture
	const initialTradeMode = useRef('');
	const initialLayout = useRef('');
	const initialTheme = useRef('');
	const initialNotification = useRef('');

	const { captureEvent } = usePostHogCapture();

	const [showGridCustomiser, setShowGridCustomiser] = useShowGridCustomiser();

	const [tradeMode, setTradeMode] = useTradeModeSetting();
	const [layoutSelection, setLayoutSelection] = useLayoutConfigSetting();

	const [notificationSelection, setNotificationSelection] =
		useNotificationPlacementSetting();

	const { theme: currentTheme, setTheme } = useTheme();

	const [settingType, setSettingType] = useState<SettingType>(
		LITE_MODE_ENABLED ? 'tradeMode' : 'layout'
	);
	const isMobile = useIsMobileScreenSize();

	const gridConfigurations =
		LITE_MODE_ENABLED && tradeMode === TradeMode.LITE
			? LITE_GRID_CONFIGURATIONS
			: GRID_CONFIGURATIONS;

	const handleChange = (value: SettingType) => {
		setSettingType(value);
	};

	const handleLayoutSelection = (value: string) => {
		setLayoutSelection(value);
	};

	const handleThemeSelection = (value: string) => {
		setTheme(value);
	};

	const handleTradeModeSelection = (value: TradeMode) => {
		setTradeMode(value);

		const selectedGridConfiguration =
			gridConfigurations.find((config) => config.value === layoutSelection) ||
			gridConfigurations[0];

		if (value === 'lite' && !selectedGridConfiguration.isLiteMode) {
			if (
				['chartOnRight', 'default'].includes(selectedGridConfiguration.value)
			) {
				setLayoutSelection('defaultLite');
			} else {
				setLayoutSelection('mirroredLite');
			}
		} else if (value === 'pro' && selectedGridConfiguration.isLiteMode) {
			if (selectedGridConfiguration.value === 'defaultLite') {
				setLayoutSelection('default');
			} else {
				setLayoutSelection('mirrored');
			}
		}
	};

	// Set refs to the initial selection of each setting type
	const resetInitialChoiceRefs = () => {
		initialTradeMode.current = tradeMode;
		initialLayout.current = layoutSelection;
		initialTheme.current = currentTheme;
		initialNotification.current = notificationSelection;
	};

	const handleClose = () => {
		const captureObject = {
			trade_mode: tradeMode !== initialTradeMode.current ? tradeMode : null,
			layout:
				layoutSelection !== initialLayout.current ? layoutSelection : null,
			theme: currentTheme !== initialTheme.current ? currentTheme : null,
			notification:
				notificationSelection !== initialNotification.current
					? notificationSelection
					: null,
		};

		const anyChanges = Object.values(captureObject).some(
			(value) => value !== null
		);

		if (anyChanges) {
			captureEvent('changed_display_settings', captureObject);
		}

		resetInitialChoiceRefs();

		setShowGridCustomiser(false);
	};

	// Set initial choice refs on first render
	useEffect(() => {
		resetInitialChoiceRefs();
	}, []);

	// Variable to track whether the grid customiser has been shown, because we only want the fade-out animations being played once the components have actually been displayed
	const [shownOnce, setShownOnce] = useState(false);

	const [shownNotificationOnce, setShownNotificationOnce] = useState(false);

	useEffect(() => {
		if (settingType === 'notifications') setShownNotificationOnce(true);
	}, [settingType]);

	useEffect(() => {
		if (showGridCustomiser) setShownOnce(true);
	}, [showGridCustomiser]);

	const options = isMobile
		? [
				{ label: 'Notifications', value: 'notifications' },
				{ label: 'Theme', value: 'theme' },
		  ]
		: [
				{ label: 'Trade Layout', value: 'layout' },
				{ label: 'Notifications', value: 'notifications' },
				{ label: 'Theme', value: 'theme' },
		  ];

	if (LITE_MODE_ENABLED && !isMobile) {
		options.unshift({ label: 'Trade Mode', value: 'tradeMode' });
	}

	return (
		<>
			<div
				className={`w-full h-full absolute z-100 ${
					showGridCustomiser ? 'block' : 'hidden'
				}`}
				onClick={handleClose}
			></div>
			<LayoutCustomiserWrapper
				className={twMerge(
					`absolute z-100 flex flex-col items-center w-full overflow-hidden`,
					!showGridCustomiser && shownOnce && 'fold-and-fade-out',
					showGridCustomiser && 'fold-and-fade-in h-auto'
				)}
			>
				{/* The below element acts as the translucent background for the dropdown panel */}
				<div
					className="absolute w-full h-full opacity-80"
					style={{
						background:
							'linear-gradient(180deg, var(--main-bg) 75%, rgba(0,0,0,0))',
					}}
				/>

				<div className="pt-4" />

				<HorizontalSwitch
					selected={settingType}
					options={options}
					onChange={handleChange}
					className="z-0"
				/>

				<div
					className={`pt-6 space-x-12 ${
						settingType === 'tradeMode' ? 'flex' : 'hidden'
					}`}
				>
					{TRADE_MODES.map((config) => (
						<TradeModeOptionButton
							key={config.value}
							value={config.value}
							onClick={() => {
								handleTradeModeSelection(config.value);
							}}
							selected={tradeMode === config.value}
						/>
					))}
				</div>

				<div
					className={`pt-6 space-x-12 ${
						settingType === 'layout' ? 'flex' : 'hidden'
					}`}
				>
					{gridConfigurations.map((config) => (
						<LayoutOptionButton
							key={config.value}
							value={config.value}
							onClick={() => {
								handleLayoutSelection(config.value);
							}}
							selected={layoutSelection === config.value}
						/>
					))}
				</div>

				<div
					className={`flex pt-6 space-x-12 ${
						settingType === 'notifications' ? 'flex' : 'hidden'
					}`}
				>
					{NOTIFICATION_CONFIGURATIONS.map((config) => (
						<NotificationOptionButton
							key={config.value}
							value={config.value}
							onClick={() => {
								setNotificationSelection(config.value);
							}}
							selected={notificationSelection === config.value}
						/>
					))}
				</div>

				<div
					className={`flex pt-6 space-x-12 ${
						settingType === 'theme' ? 'flex' : 'hidden'
					}`}
				>
					{THEMES.map((config) => (
						<ThemeOptionButton
							key={config.value}
							value={config.value}
							onClick={() => {
								handleThemeSelection(config.value);
							}}
							selected={currentTheme === config.value}
						/>
					))}
				</div>

				<div className="absolute top-4 right-8">
					<XButton onClick={handleClose} stroke="var(--text-default)" />
				</div>

				<div className="pt-4" />
				<div className="pt-12" />
			</LayoutCustomiserWrapper>

			{shownOnce && (
				<Portal>
					<NotficationPreview
						className={`absolute ${
							!showGridCustomiser && `pointer-events-none`
						} ${notificationSelection} ${
							showGridCustomiser && settingType === 'notifications'
								? 'fade-in'
								: `${shownNotificationOnce ? 'fade-out' : 'opacity-0'}`
						}`}
					>
						<img
							src={`/assets/icons/notificationSkeleton-${currentTheme}.svg`}
						/>
					</NotficationPreview>
				</Portal>
			)}
		</>
	);
};

export default LayoutCustomiser;
