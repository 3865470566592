'use client';

import useCurrentSettings from 'src/hooks/useCurrentSettings';
import useDriftActions from 'src/hooks/useDriftActions';
import { useCurrentRpcLatency } from 'src/hooks/useRpcLatency';
import Text from './Text/Text';
import { twMerge } from 'tailwind-merge';
import RpcSelectorPopup from './RpcSelectorPopup';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import PopoverWrapper from './PopoverWrapper';

const RpcHealthBars = ({ latency }: { latency: number }) => {
	const barColors =
		latency < 0
			? ['bg-grey-50', 'bg-grey-50', 'bg-grey-50']
			: latency < 100
			? ['bg-green-50', 'bg-green-50', 'bg-green-50']
			: latency < 250
			? ['bg-yellow-50', 'bg-yellow-50', 'bg-grey-50']
			: ['bg-red-50', 'bg-blueGrey-70', 'bg-blueGrey-70'];

	return (
		<div className="w-4 h-4 space-x-[2px] flex justify-between items-baseline py-0.5">
			<div
				className={twMerge(
					'w-[33%] h-[40%] border border-container-border rounded-t-sm',
					barColors[0]
				)}
			/>
			<div
				className={twMerge(
					'w-[33%] h-[70%] border border-container-border rounded-t-sm',
					barColors[1]
				)}
			/>
			<div
				className={twMerge(
					'w-[33%] h-[100%] border border-container-border rounded-t-sm',
					barColors[2]
				)}
			/>
		</div>
	);
};

const CurrentRpcHealth = (props: { mobile?: boolean }) => {
	// const currentRpcHealth = useCurrentRpcHealth();
	const currentRpcLatency = useCurrentRpcLatency();
	const actions = useDriftActions();
	const [settings] = useCurrentSettings();

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			placement: 'top',
		},
		{
			offset: 12,
			disableAutoPlacement: true,
		}
	);

	const avg = currentRpcLatency?.avg || null;

	const showNetworkSettingsModal = () => {
		actions.showModal('showNetworkModal');
	};

	if (props.mobile) {
		return (
			<button
				className={`hover:opacity-75 text-text-default w-full flex flex-col space-y-0.5`}
				onClick={showNetworkSettingsModal}
			>
				<div className="flex flex-row items-center space-x-2">
					<RpcHealthBars latency={avg} />
					<Text.BODY3>RPC</Text.BODY3>
				</div>
				<div className="whitespace-nowrap ">
					<Text.MICRO1 className="text-interactive-link">
						{settings.rpcEndpoint?.label} {avg && `(${avg}ms)`}
					</Text.MICRO1>
				</div>
			</button>
		);
	}

	return (
		<>
			{isPopoverOpen && (
				<PopoverWrapper
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
				>
					<RpcSelectorPopup />
				</PopoverWrapper>
			)}
			<button
				onClick={() => setIsPopoverOpen(!isPopoverOpen)}
				className={`flex items-center transition-colors hover:opacity-75 text-xs`}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				<span className="flex flex-wrap justify-center">
					<div className="flex items-center overflow-y-none">
						<RpcHealthBars latency={avg} />
						<Text.BODY3 className="ml-1 text-text-default">RPC:</Text.BODY3>
						<Text.BODY3 className="ml-1 text-purple-40">{`${
							settings.rpcEndpoint?.label
						}${avg && avg != null ? ` (${avg}ms)` : ``}`}</Text.BODY3>
					</div>
				</span>
			</button>
		</>
	);
};

export default CurrentRpcHealth;
