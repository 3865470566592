'use client';

import Tippy, { TippyProps, useSingleton } from '@tippyjs/react';
import EventEmitter from 'events';
import React, { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';

type TooltipProps = {
	content: ReactNode;
	className?: string;
	// Not sure about naming convention for the className prop.
	// className is for the child node that gets wrapped
	// tooltipClassName is for the tooltip itself
	tooltipClassName?: string;
	placement?: TippyProps['placement'];
	disabled?: boolean;
	size?: number;
	allowHover?: boolean;
	showArrow?: boolean;
	visible?: boolean;
	manualEmitter?: EventEmitter;
	maxWidth?: string;
	customArrow?: ReactNode;
	zIndex?: number;
	slim?: boolean;
	singletonTarget?: ReturnType<typeof useSingleton>[0];
	showOnClick?: boolean; // Controls whether the tooltip should show(and toggle) on click instead of based on hover action
	closeEmitter?: EventEmitter;
};

/**
 * -- NOTE:
 *
 * The Tippy component gets its styling from the tippy.js/dist/tippy.css imported in _app.tsx.
 *
 * Using tailwind, no way to force our styles to override some of the default styles (unless making the tailwind styles have "!important" which isn't ideal)
 *
 * Therefore, we are using Tooltip.css (located in the components folder), which is imported into _app.tsx (a next.js requirement) to override some of the
 * default styles
 */
const TippyWrapper = ({
	children,
	content,
	className,
	placement = 'bottom',
	disabled,
	allowHover,
	showArrow = false,
	visible,
	manualEmitter,
	zIndex,
	showOnClick,
	closeEmitter,
}: PropsWithChildren<TooltipProps>) => {
	const tippyRef = useRef<HTMLElement>(null);

	const interactive = !!allowHover;

	useEffect(() => {
		if (manualEmitter) {
			const handler = () => {
				// @ts-ignore
				const instance = tippyRef.current._tippy;
				instance.show();
			};

			manualEmitter.on('event', handler);

			return () => {
				manualEmitter.removeListener('event', handler);
			};
		}
	}, [manualEmitter]);

	useEffect(() => {
		if (closeEmitter) {
			const closeHandler = () => {
				// @ts-ignore
				const instance = tippyRef.current._tippy;
				instance.hide();
			};

			closeEmitter.on('event', closeHandler);

			return () => {
				closeEmitter.removeListener('event', closeHandler);
			};
		}
	}, [closeEmitter]);

	return (
		<Tippy
			interactive={interactive}
			disabled={disabled}
			visible={visible}
			content={content}
			arrow={showArrow}
			placement={placement}
			ref={tippyRef}
			zIndex={zIndex ?? 200}
			hideOnClick={showOnClick ? true : undefined}
			trigger={showOnClick ? 'click' : undefined}
		>
			<div className={`${className}`}>{children}</div>
		</Tippy>
	);
};

export default React.memo(TippyWrapper);
