import React from 'react';

const DriftGraphicLogo = (props: { className?: string }) => {
	return (
		<img
			src="/assets/icons/driftIcon.svg"
			className={`${props.className ?? ''}`}
		/>
	);
};

export default DriftGraphicLogo;
