'use client';

import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';
import XButton from '../Buttons/XButton';
import { PropsWithChildren, ReactNode, useState } from 'react';

export type StatusBarProps = {
	message: string | ReactNode;
	colorClass?: string;
};

const StatusBarXButton = (props: { onClick: () => void }) => {
	return (
		<>
			<div
				className={twMerge(
					`absolute -translate-y-1/2 right-[10px] w-[20px] h-[20px] top-1/2`
				)}
			>
				<XButton size={20} stroke="var(--black)" onClick={props.onClick} />
			</div>
		</>
	);
};

const StatusBar = (props: StatusBarProps) => {
	const [hidden, setHidden] = useState(false);

	if (hidden) return undefined;

	return (
		<div
			className={twMerge(
				`relative flex items-center justify-center flex-grow w-full py-1 border-container-border text-center overflow-hidden`,
				props.colorClass ?? 'bg-orange-50'
			)}
		>
			<Text.BODY3 className="w-full text-black flex-fill pl-2 pr-[40px] break-words">
				{props.message}
			</Text.BODY3>
			<StatusBarXButton
				onClick={() => {
					setHidden(true);
				}}
			/>
		</div>
	);
};

export const StatusBarWithContent = (
	props: PropsWithChildren & {
		className?: string;
	}
) => {
	const [hidden, setHidden] = useState(false);

	if (hidden) return undefined;

	return (
		<div
			className={twMerge(
				`relative flex items-center justify-center flex-grow w-full py-1 bg-orange-50 border-container-border text-center overflow-hidden`,
				props.className
			)}
		>
			<Text.BODY3 className="w-full text-black flex-fill pl-2 pr-[40px] break-words">
				{props.children}
			</Text.BODY3>
			<StatusBarXButton
				onClick={() => {
					setHidden(true);
				}}
			/>
		</div>
	);
};

export default StatusBar;
