'use client';

import { useEffect, useMemo } from 'react';
import Text from './Text/Text';
import PriorityFeeSettingsInputs from './Settings/Parts/PriorityFeeSettingsInputs';
import Button from './Button';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import React from 'react';
import { useImmer } from 'use-immer';

const PriorityFeePopup = (props: { onClose: () => void }) => {
	const { captureEvent } = usePostHogCapture();

	const [savedSettings, updateSettings] = useCurrentSettings();

	const [stagedSettings, setStagedSettings] = useImmer(savedSettings);

	const handleSave = () => {
		updateSettings(stagedSettings);
	};

	const isInputChanged = useMemo(() => {
		return (
			savedSettings?.priorityFee !== stagedSettings?.priorityFee ||
			savedSettings?.customPriorityFee !== stagedSettings?.customPriorityFee ||
			savedSettings?.maxPriorityFeeCap !== stagedSettings?.maxPriorityFeeCap
		);
	}, [savedSettings, stagedSettings]);

	const inputValid = useMemo(() => {
		// if changing priority fee to custom, must be a value there
		return (
			stagedSettings &&
			(stagedSettings.priorityFee !== 'custom' ||
				(stagedSettings.priorityFee === 'custom' &&
					!isNaN(stagedSettings.customPriorityFee))) &&
			!isNaN(stagedSettings?.maxPriorityFeeCap) &&
			stagedSettings?.maxPriorityFeeCap > 0
		);
	}, [stagedSettings]);

	useEffect(() => {
		captureEvent('priority_fee_footer_menu_opened');
	}, []);

	return (
		<div className="p-4 relative rounded-md bg-container-bg w-[400px] z-100">
			<PriorityFeeSettingsInputs
				localSettings={stagedSettings}
				setLocalSettings={setStagedSettings}
			/>
			<div className="flex justify-end mt-3 space-x-1">
				<Button.Secondary
					size="MEDIUM"
					onClick={() => {
						setStagedSettings(savedSettings);
						props.onClose();
					}}
				>
					<Text.H3>Cancel</Text.H3>
				</Button.Secondary>
				<Button.Primary
					size="MEDIUM"
					onClick={() => {
						handleSave();
						props.onClose();
					}}
					disabled={!isInputChanged || !inputValid}
				>
					<Text.H3>Save</Text.H3>
				</Button.Primary>
			</div>
		</div>
	);
};

export default PriorityFeePopup;
