'use client';

import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DesktopCategoryOption } from '.';
import Text from '../Text/Text';
import { NavigationState } from './useNavigationState';
import React from 'react';
import { NavigationStore } from 'src/stores/useNavigationStore';
import { AdvancedChip, NewChip } from '../Chips/AdvancedFeatureChip';
import { Discord, Github, Open, Twitter } from '@drift-labs/icons';
import UI_UTILS from 'src/utils/uiUtils';
import { DRIFT_DISCORD_URL } from 'src/constants/constants';

type DesktopNavigationPopupProps = {
	currentNavigationSelection: NavigationStore['currentNavigationSelection'];
	navigationCategoryData: DesktopCategoryOption;
	onClick: NavigationState['handleNavigation'];
};

type CategoryOptionProps = Pick<
	DesktopNavigationPopupProps,
	'onClick' | 'currentNavigationSelection'
> & { categoryOptionData: DesktopCategoryOption['options'][0] };

const CategoryOption = ({
	onClick,
	currentNavigationSelection,
	categoryOptionData,
}: CategoryOptionProps) => {
	const Icon = categoryOptionData.icon;

	const [isHovered, setIsHovered] = useState(false);

	const isSelected = currentNavigationSelection === categoryOptionData.key;
	const isHighlighted = isHovered || isSelected;

	return (
		<div
			onMouseOver={() => {
				setIsHovered(true);
			}}
			onMouseOut={() => {
				setIsHovered(false);
			}}
			data-puppet-tag={categoryOptionData.key}
			className={twMerge(
				`flex px-1 py-1 space-x-2 text-text-label hover:cursor-pointer`,
				categoryOptionData.description ? 'items-start' : 'items-center'
			)}
			onClick={() => {
				onClick(categoryOptionData.handler());
			}}
		>
			<div
				className={twMerge(
					`grid grid-cols-[20px_1fr] gap-x-2`,
					categoryOptionData?.description ? 'grid-auto-rows' : 'grid-auto-rows'
				)}
			>
				<span className="flex flex-col justify-center h-full">
					{isHighlighted ? (
						<Icon size={18} color="url(#icon-gradient)" />
					) : (
						<Icon size={18} className="text-text-label" />
					)}
				</span>

				<span className="flex items-center justify-between gap-3">
					<span className="flex flex-col justify-center h-full">
						<Text.BODY2
							className={twMerge('inline-flex items-center whitespace-nowrap')}
						>
							<span
								className={twMerge(
									isHighlighted ? 'drift-gradient-text' : 'text-text-label'
								)}
							>
								{categoryOptionData.name}
							</span>
							{categoryOptionData.advanced && (
								<AdvancedChip className="text-white" />
							)}
							<span
								className={twMerge(
									isHighlighted ? 'drift-gradient-text' : 'text-text-label'
								)}
							>
								{categoryOptionData.new && <NewChip className="mb-0.5 ml-1" />}
							</span>
						</Text.BODY2>
					</span>

					{categoryOptionData.isExternalLink && <Open size={16} />}
				</span>
				<span>{/* Blank */}</span>
				{categoryOptionData.description && (
					<div className="text-static-default leading-[1.2]">
						<Text.BODY3>{categoryOptionData.description}</Text.BODY3>
					</div>
				)}
			</div>
		</div>
	);
};

const optionsClassName =
	'flex items-center justify-center cursor-pointer hover:text-text-emphasis';

const DesktopNavigationPopup = (props: DesktopNavigationPopupProps) => {
	const isMoreCategory = props.navigationCategoryData?.categoryName === 'More';

	return (
		<span className="p-2 flex flex-col border bg-container-bg border-container-border max-w-[280px] border-r-1 rounded divide-y divide-container-border">
			<div
				className={twMerge('flex flex-col space-y-1', isMoreCategory && 'pb-2')}
			>
				{props.navigationCategoryData.options.map((option) => (
					<CategoryOption
						key={option.name}
						currentNavigationSelection={props.currentNavigationSelection}
						onClick={props.onClick}
						categoryOptionData={option}
					/>
				))}
			</div>

			{isMoreCategory && (
				<div className="flex items-center justify-between px-1 pt-3 pb-1 divide-x divide-container-border text-text-secondary">
					<Text.BODY3
						className="cursor-pointer hover:text-text-emphasis"
						onClick={() =>
							UI_UTILS.openUrl(
								'https://docs.drift.trade/legal-and-regulations/disclaimer'
							)
						}
					>
						Disclaimers
					</Text.BODY3>
					<Text.BODY3
						className="flex items-center h-4 pl-4 cursor-pointer hover:text-text-emphasis"
						onClick={() =>
							UI_UTILS.openUrl(
								'https://docs.drift.trade/legal-and-regulations/terms-of-use'
							)
						}
					>
						Terms
					</Text.BODY3>
					<div className="flex items-center justify-between gap-4 pl-4">
						<span
							className={optionsClassName}
							onClick={() => UI_UTILS.openUrl(DRIFT_DISCORD_URL)}
						>
							<Discord />
						</span>
						<span
							className={optionsClassName}
							onClick={() =>
								UI_UTILS.openUrl('https://twitter.com/DriftProtocol')
							}
						>
							<Twitter />
						</span>
						<span
							className={optionsClassName}
							onClick={() => UI_UTILS.openUrl('https://github.com/drift-labs')}
						>
							<Github />
						</span>
					</div>
				</div>
			)}
		</span>
	);
};

export default React.memo(DesktopNavigationPopup);
