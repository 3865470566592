'use client';

import {
	DRIFT_CUSTOM_WALLET_ICONS,
	DRIFT_CUSTOM_WALLET_OPTIONS,
} from 'src/constants/wallets';
import usePrefferredWalletSetting from 'src/hooks/usePrefferredWalletSetting';
import useWalletsToDisplay from 'src/hooks/useWalletsToDisplay';
import { useMemo } from 'react';

const usePreferredWalletIconInfo = () => {
	const [preferredWallet] = usePrefferredWalletSetting();

	const { walletsToShow, hiddenWallets } = useWalletsToDisplay(1);

	const preferredMagicAuth =
		preferredWallet ===
		DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME;

	const preferredMetamask =
		preferredWallet === DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME;

	const preferredAppKit =
		preferredWallet === DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME;

	const preferredWalletProvider = [...walletsToShow, ...hiddenWallets].find(
		(wallet) => wallet?.provider.adapter.name === preferredWallet
	);

	const preferredWalletName = preferredAppKit
		? DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME
		: preferredMagicAuth
		? DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME
		: preferredMetamask
		? DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME
		: preferredWalletProvider?.provider?.adapter?.name;

	const preferredWalletIconSrc = preferredAppKit
		? DRIFT_CUSTOM_WALLET_ICONS.APPKIT_WALLET_ADAPTER
		: preferredMagicAuth
		? DRIFT_CUSTOM_WALLET_ICONS.MAGIC_AUTH_WALLET_ADAPTER
		: preferredMetamask
		? DRIFT_CUSTOM_WALLET_ICONS.SNAP_WALLET_ADAPTER
		: preferredWalletProvider?.provider?.adapter?.icon;

	const result = useMemo(() => {
		return {
			iconSrc: preferredWalletIconSrc,
			walletName: preferredWalletName,
		};
	}, [preferredWalletIconSrc, preferredWalletName]);

	return result;
};

export default usePreferredWalletIconInfo;
