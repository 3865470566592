'use client';

import { PropsWithChildren } from 'react';
import {
	DESKTOP_NAVIGATION_OPTIONS,
	DesktopCategoryOption,
	DesktopNavigationOption,
	DesktopStaticOption,
	NavigationCategory,
	featureFlagFilter,
} from '.';
import Chevron from '../Icons/Chevron';
import Text from '../Text/Text';
import TippyWrapper from '../Tooltip/TippyWrapper';
import GradientText from '../Utils/GradientText';
import DesktopNavigationPopup from './DesktopNavigationPopup';
import useNavigationState, { NavigationState } from './useNavigationState';
import { twMerge } from 'tailwind-merge';
import Tooltip from '../Tooltip/Tooltip';
import { Help } from '@drift-labs/icons';
import MarketIcon from '../Utils/MarketIcon';
import { AlphaChip, NewChip } from '../Chips/AdvancedFeatureChip';
import useNavigationStore from 'src/stores/useNavigationStore';

const getDesktopNavCategoryButtonKey = (category: NavigationCategory) => {
	return `desktop_navigation_category_${category}`;
};

const DesktopNavButton = ({
	active,
	onClick,
	className,
	...props
}: PropsWithChildren<{
	active: boolean;
	onClick: () => void;
	className?: string;
	id?: string;
}>) => {
	return (
		<div
			id={props?.id}
			className={twMerge(
				`h-full flex items-center whitespace-nowrap font-medium self-center px-2 md:px-3 py-1 hover:cursor-pointer rounded hover:brightness-125 select-none text-text-label`,
				active && 'bg-container-bg-selected',
				className || 'bg-main-bg hover:bg-container-bg-selected'
			)}
			onClick={onClick}
		>
			{/* pointer-events-none so that the child elements don't prevent the mouse-in/out events */}
			<span className={'flex items-center space-x-1 pointer-events-none'}>
				{props.children}
			</span>
		</div>
	);
};

const CategoryButton = ({
	navigationCategory,
	navigationOption,
	active,
	handleNavigation,
	className,
	isNew,
	isAlpha,
}: {
	navigationCategory: NavigationCategory;
	navigationOption: DesktopNavigationOption;
	active: boolean;
	handleNavigation: NavigationState['handleNavigation'];
	className?: string;
	isNew?: boolean;
	isAlpha?: boolean;
}) => {
	const buttonType = navigationOption.type;
	const buttonLabel = navigationOption.categoryName;
	const useHelpIcon = buttonLabel === 'Feedback';
	const useDriftIcon = navigationCategory === 'driftStaking';

	const handleClick = () => {
		switch (navigationOption.type) {
			case 'staticOption': {
				handleNavigation(navigationOption.handler());
				return;
			}
			case 'categoryOption': {
				return;
			}
		}
	};

	if (buttonType === 'staticOption') {
		const child = useHelpIcon ? (
			<Help size={18} />
		) : (
			<Text.BODY2>{buttonLabel}</Text.BODY2>
		);

		return useHelpIcon ? (
			<Tooltip content={'Submit feedback or get help'}>
				<DesktopNavButton
					id={getDesktopNavCategoryButtonKey(navigationCategory)}
					onClick={handleClick}
					active={active}
					className={className}
				>
					{active ? <GradientText>{child}</GradientText> : child}
				</DesktopNavButton>
			</Tooltip>
		) : useDriftIcon ? (
			<DesktopNavButton
				id={getDesktopNavCategoryButtonKey(navigationCategory)}
				onClick={handleClick}
				active={active}
				className={className}
			>
				{<MarketIcon marketSymbol={'DRIFT'} grey={!active} />}
				{active ? <GradientText>{child}</GradientText> : child}
			</DesktopNavButton>
		) : (
			<DesktopNavButton
				id={getDesktopNavCategoryButtonKey(navigationCategory)}
				onClick={handleClick}
				active={active}
				className={className}
			>
				{active ? <GradientText>{child}</GradientText> : child}
				{isNew && <NewChip />}
				{isAlpha && <AlphaChip />}
			</DesktopNavButton>
		);
	}

	return (
		<DesktopNavButton
			id={getDesktopNavCategoryButtonKey(navigationCategory)}
			onClick={handleClick}
			active={active}
			className={className}
		>
			{active ? (
				<GradientText>
					<Text.BODY2>{buttonLabel}</Text.BODY2>
				</GradientText>
			) : (
				<Text.BODY2>{buttonLabel}</Text.BODY2>
			)}
			{isNew && <NewChip />}
			{isAlpha && <AlphaChip />}
			<Chevron direction="down" className="w-3" />
		</DesktopNavButton>
	);
};

const DesktopNavigation = () => {
	const navigationState = useNavigationState();
	const currentNavigationSelection = useNavigationStore(
		(s) => s.currentNavigationSelection
	);
	const currentNavigationCategory = useNavigationStore(
		(s) => s.currentNavigationCategory
	);

	return (
		<>
			<div data-puppet-tag="desktop_navigation" className="flex">
				{Object.entries(DESKTOP_NAVIGATION_OPTIONS)
					.filter((option) => featureFlagFilter(option[1]))
					.map(([navigationCategory, navOption]) => {
						return (
							<TippyWrapper
								key={`${currentNavigationSelection}_${navigationCategory}`}
								content={
									navOption.type === 'categoryOption' ? (
										<DesktopNavigationPopup
											navigationCategoryData={
												DESKTOP_NAVIGATION_OPTIONS[
													navigationCategory
												] as DesktopCategoryOption
											}
											onClick={navigationState.handleNavigation}
											currentNavigationSelection={currentNavigationSelection}
										/>
									) : (
										<></>
									)
								}
								allowHover
								showOnClick
							>
								<CategoryButton
									className={navOption.className}
									navigationCategory={navigationCategory as NavigationCategory}
									navigationOption={navOption}
									active={currentNavigationCategory === navigationCategory}
									handleNavigation={navigationState.handleNavigation}
									isNew={(navOption as DesktopStaticOption).isNew}
									isAlpha={(navOption as DesktopStaticOption).isAlpha}
								/>
							</TippyWrapper>
						);
					})}
			</div>
		</>
	);
};

export default DesktopNavigation;
