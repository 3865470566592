'use client';

import Button from '../Button';
import Text from '../Text/Text';
import { BASE_NAVIGATION_OPTIONS, RouteNavigationResult } from '../Navigation';
import Link from 'next/link';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useFuelEarnings from 'src/hooks/useFuelEarnings';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useAccountExists from 'src/hooks/useAccountExists';
import { BN } from '@drift-labs/sdk';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const ONE_THOUSAND = new BN(1000);

export const FuelChip = () => {
	const isMobile = useIsMobileScreenSize();
	const connected = useWalletIsConnected();
	const accountExists = useAccountExists();
	const fuelEarnings = useFuelEarnings();
	const loaded = fuelEarnings?.loaded;
	const totalFuel = fuelEarnings?.totalFuel;

	const emulationMode = useDriftStore((s) => s.isEmulatingAccount);
	const displayFuelNumber = (emulationMode || connected) && accountExists;

	return (
		<Link
			href={
				(BASE_NAVIGATION_OPTIONS.fuel.handler() as RouteNavigationResult).route
			}
			className={'hover:opacity-100'}
		>
			<Button.Ghost size="SMALL" className="flex items-center h-full gap-2">
				<img src="/assets/images/fuel/fuel-droplet.svg" alt="Fuel droplet" />
				{!isMobile && displayFuelNumber && totalFuel ? (
					loaded ? (
						<Text.H3 className="hidden font-bold sm:block">
							{totalFuel.gte(ONE_THOUSAND)
								? totalFuel.toMillified()
								: totalFuel.toNum()}
						</Text.H3>
					) : (
						<SkeletonValuePlaceholder className="w-8 h-5" loading />
					)
				) : (
					<Text.H3 className="hidden font-bold sm:block">Fuel</Text.H3>
				)}
			</Button.Ghost>
		</Link>
	);
};
