'use client';

import React, { useEffect } from 'react';
import useCurrentPerpMarketAccount from '../../hooks/useCurrentPerpMarketAccount';
import { ENUM_UTILS } from '@drift/common';
import { MarketStatus } from '@drift-labs/sdk';
import StatusBar from './StatusBar';
import Link from '../Utils/Link';
import { DRIFT_DISCORD_URL } from '../../constants/constants';
import useInfoForCurrentlySelectedMarket from '../../hooks/useInfoForCurrentlySelectedMarket';

enum MarketBannerStatus {
	DEFAULT,
	VAMM_DISABLED,
}

const MarketStatusBanner = () => {
	const currentlySelectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const currentlySelectedPerpMarket = useCurrentPerpMarketAccount();

	const [marketStatus, setMarketStatus] = React.useState<MarketBannerStatus>(
		MarketBannerStatus.DEFAULT
	);

	useEffect(() => {
		if (!currentlySelectedPerpMarket) {
			setMarketStatus(MarketBannerStatus.DEFAULT);
			return;
		}

		if (
			ENUM_UTILS.match(
				currentlySelectedPerpMarket.status,
				MarketStatus.AMM_PAUSED
			)
		) {
			setMarketStatus(MarketBannerStatus.VAMM_DISABLED);
			return;
		}

		setMarketStatus(MarketBannerStatus.DEFAULT);
	}, [currentlySelectedPerpMarket]);

	if (marketStatus === MarketBannerStatus.DEFAULT) return null;

	if (marketStatus === MarketBannerStatus.VAMM_DISABLED) {
		return (
			<StatusBar
				message={
					<div className="flex justify-center">
						{`AMM trades have been paused for ${currentlySelectedMarketInfo?.info?.config?.symbol}. Users can continue to trade against one another. Settling P&L has been temporarily disabled. For the latest updates,`}
						<Link
							className="ml-1 underline text-black"
							href={DRIFT_DISCORD_URL}
						>{`visit our discord.`}</Link>
					</div>
				}
			/>
		);
	}
};

export default MarketStatusBanner;
