'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import StatusBar from './StatusBar';
import { useRef, useState, useEffect } from 'react';
import useAccountExists from 'src/hooks/useAccountExists';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';

const ReferralBar = () => {
	const referrerParam = useDriftStore((s) => s.referrerParam);
	const referrerParamFailed = useDriftStore((s) => s.referrerParamFailed);

	const driftClient = useDriftClient();
	const alreadyDoneNotificationLoop = useRef(false);
	const accountExists = useAccountExists();
	const isSubscribed = useDriftClientIsReady();

	const [noticeText, setNoticeText] = useState('');
	const [colorClass, setColorClass] = useState(undefined);

	const showSuccess = () => {
		const stateAccount = driftClient.getStateAccount();
		const feeTier = stateAccount?.perpFeeStructure?.feeTiers?.[0];
		const refereeDiscount =
			(feeTier.refereeFeeNumerator / feeTier.refereeFeeDenominator) * 100;

		setNoticeText(
			`Referral code: ${referrerParam} | Connect your wallet and deposit to save ${refereeDiscount}% off trading fees forever.`
		);
		setColorClass('bg-green-40');
	};

	const showFailure = (accountExists: boolean) => {
		if (accountExists) {
			setNoticeText(
				`Referral failed; you can't apply a referral code to a pre-existing Drift account.`
			);
		} else {
			setNoticeText(
				`Referral failed; the referral code requested was not found.`
			);
		}
	};

	/**
	 * There are three cases to handle:
	 * => Completely new, never connected wallet : want to show "success"
	 * => Connected wallet before but not deposited : want to show "success" (the account only gets created upon deposit)
	 * => Connected wallet and deposited before : want to show "warning"
	 *
	 * Also only want to run this logic once, hence the alreadyDoneNoticicationLoop flag.
	 */
	useEffect(() => {
		if (
			alreadyDoneNotificationLoop.current ||
			!driftClient ||
			!driftClient.isSubscribed
		) {
			return;
		}

		// if referrer param
		// and driftClient is subscribed
		if ((referrerParam || referrerParamFailed) && driftClient.isSubscribed) {
			alreadyDoneNotificationLoop.current = true;

			//// wallet connected + account exists => can't use a referral code on a pre-existing account
			if (accountExists || referrerParamFailed) {
				showFailure(accountExists);
				return;
			}

			//// else => referral code successfully applied
			showSuccess();
		}
	}, [
		driftClient,
		isSubscribed,
		referrerParam,
		accountExists,
		referrerParamFailed,
	]);

	if (!noticeText) {
		return;
	}

	return <StatusBar message={noticeText} colorClass={colorClass} />;
};

export default ReferralBar;
