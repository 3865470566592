import EmulationBar from './EmulationBar';
import NewVersionBar from './NewVersionBar';
import MaintenanceModeBar from './MaintenanceModeBar';
import MarketStatusBanner from './MarketStatusBanner';
import SolanaDownStatus from './SolanaDownStatus';
import ReferralBar from './ReferralBar';
import { ArbitraryStatusBar } from './ArbitraryStatusBar';

const StatusBars = () => {
	return (
		<div className="flex flex-col divide-y divide-solid">
			<NewVersionBar />
			<EmulationBar />
			<ReferralBar />
			<MaintenanceModeBar />
			<MarketStatusBanner />
			<SolanaDownStatus />
			<ArbitraryStatusBar />
		</div>
	);
};

export default StatusBars;
